var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as React from 'react';
import { useEffect } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import Typography from '@mui/material/Typography';
import ProgressBar from '@ramonak/react-progress-bar';
import moment from 'moment';
import { useStore } from 'react-context-hook';
import Countdown, { zeroPad } from 'react-countdown';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { Col, Row } from 'react-styled-flexboxgrid';
import useSound from 'use-sound';
import * as yup from 'yup';
import { NewQuestionBodyComponent } from '@app/components/atm.question-component-new/new-question-body.component';
import { VideoPlayer } from '@app/components/atm.video-player';
import HTMLDisplayToQuestion from '@app/components/htmlToText';
import { HeaderV2 } from '@app/components/mol.header/header.component-v2';
import { BottomNav } from '@app/components/mol.track-bottom-nav';
import { axiosClient } from '@app/configure-app';
import { Footer, StyledSlider } from '@app/modules/questionnarie/organizational-climate.style';
import { QuestionnaireContext } from '@app/providers/questionnaire.store';
import { AppPath } from '@app/routes';
import { convertFileToBase64, removeSpecialChars } from '@app/utils/shared-functions.utils';
import entcoinsIconSrc from '@assets/icons/ENTCoins.svg';
import newCheckCircle from '@assets/icons/new-check-circle.svg';
import newCheckIcon from '@assets/icons/new-check-icon.svg';
import { ActivityIndicator, Button, FontFamily, Separator, StyledContainer } from '@atomic';
import { InputDate } from '@atomic/atom.Input-date/input-date-component.style';
import { QuestionCard, Textarea } from '../../components/atm.question-component-new/new-question-body.style';
import { ButtonAndamento } from '../quiz/components/quiz-win/quiz-win.styled';
import { FlexColumn, FlexRowBot } from '../routine-checklist/quick-checklist/styles';
import { RoundedButton, SubmenuTitle } from '../submenus/components/styles';
import { FlexCol, FlexRow } from '../submenus/components/submenu-content-card/styles';
import useDebounce from './hooks/useDebounce';
import { useSearchUser } from './hooks/useSearchUser';
import { ErrorResponse, InputSearch, NumberInput, OuterDiv, PreviewImage, PreviewLabel } from './questionnaire.styled';
// eslint-disable-next-line @typescript-eslint/no-var-requires
var rightAnswerSound = require('@assets/sounds/right-answer-sound.mp3');
export var ITipoPergunta;
(function (ITipoPergunta) {
    ITipoPergunta[ITipoPergunta["MultiplaEscolha"] = 1] = "MultiplaEscolha";
    ITipoPergunta[ITipoPergunta["Texto"] = 2] = "Texto";
    ITipoPergunta[ITipoPergunta["Numero"] = 3] = "Numero";
    ITipoPergunta[ITipoPergunta["Imagem"] = 4] = "Imagem";
    ITipoPergunta[ITipoPergunta["MultiplaEscolhaComOutros"] = 5] = "MultiplaEscolhaComOutros";
    ITipoPergunta[ITipoPergunta["Data"] = 6] = "Data";
    ITipoPergunta[ITipoPergunta["EscalaEmNumero"] = 7] = "EscalaEmNumero";
    ITipoPergunta[ITipoPergunta["AssociarUsuario"] = 8] = "AssociarUsuario";
})(ITipoPergunta || (ITipoPergunta = {}));
var QUESTION_URL = '/questionario-empresa';
var DEFAULT_QUIZZ_POINTS_VALUE = 30;
export var NewQuestionnaire = function () {
    var _a, _b, _c;
    var id = useParams().id;
    var company = useStore('company')[0];
    var isMute = useStore('mute')[0];
    var playRightAnswerSound = useSound(rightAnswerSound)[0];
    var _d = React.useState(0), currentQuestionIndex = _d[0], setCurrentQuestionIndex = _d[1];
    var _e = React.useState(), currentQuestion = _e[0], setCurrentQuestion = _e[1];
    var _f = React.useState(0), currentAnswerId = _f[0], setCurrentAnswerId = _f[1];
    var _g = React.useState(''), answerValue = _g[0], setAnswerValue = _g[1];
    var _h = React.useState(), question = _h[0], setQuestion = _h[1];
    var _j = React.useState(false), loading = _j[0], setLoading = _j[1];
    var _k = React.useState(false), answered = _k[0], setAnswered = _k[1];
    var _l = React.useState(false), readyAnswer = _l[0], setReadyAnswer = _l[1];
    var _m = React.useState(''), base64Image = _m[0], setBase64Image = _m[1];
    var _o = React.useState('black'), isValid = _o[0], setIsValid = _o[1];
    var _p = React.useState(0), progress = _p[0], setProgress = _p[1];
    var _q = React.useState(false), finishedQuestions = _q[0], setFinishedQuestions = _q[1];
    var _r = React.useState(''), userSearch = _r[0], setUserSearch = _r[1];
    var searchDebounced = useDebounce(userSearch, 800);
    var _s = React.useState({
        pageNumber: 1,
        pageSize: 3,
        search: '',
    }), params = _s[0], setParams = _s[1];
    var bottomRef = React.useRef(null);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    var _t = React.useContext(QuestionnaireContext), _ = _t[0], setQuestionnaire = _t[1];
    var history = useHistory();
    // attempts should never be zero, just 1 or 2
    var _u = React.useState(1), attempts = _u[0], setAttempts = _u[1];
    var _v = React.useState(0), totalPoints = _v[0], setTotalPoints = _v[1];
    var schema = yup.object({
        resposta: yup.string().max(900, 'Sua resposta deve ter no máximo 900 caracteres.').required('Campo obrigatório.'),
    });
    var handleRightAnswer = function () {
        if (!isMute) {
            playRightAnswerSound();
        }
        setLoading(false);
        setAnswered(true);
        // if the question is not worth points then 0
        setTotalPoints(function (prevTotalPoints) { return prevTotalPoints + (question.valePontos ? DEFAULT_QUIZZ_POINTS_VALUE / attempts : 0); });
        // attempts should never be zero, just 1 or 2
        setAttempts(1);
    };
    var handleWrongAnswer = function () {
        setAttempts(function (prevAttempts) { return prevAttempts + 1; });
    };
    var isLoading = useQuery(['get-research-question', setQuestion, id, setQuestionnaire], function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axiosClient.get(QUESTION_URL + "/" + id)];
                case 1:
                    response = _a.sent();
                    setQuestion(response.data.data);
                    setQuestionnaire(response.data.data);
                    return [2 /*return*/, response];
                case 2:
                    error_1 = _a.sent();
                    console.log(error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }, {
        refetchOnWindowFocus: false,
    }).isLoading;
    React.useEffect(function () {
        setParams(__assign(__assign({}, params), { search: searchDebounced }));
    }, [searchDebounced]);
    var _w = useSearchUser(params), users = _w.data, hasNextPage = _w.hasNextPage, fetchNextPage = _w.fetchNextPage, isFetching = _w.isFetching;
    var usersData = React.useMemo(function () { var _a; return ((_a = users === null || users === void 0 ? void 0 : users.pages) === null || _a === void 0 ? void 0 : _a.flatMap(function (page) { return page.data.results; })) || []; }, [users]);
    useEffect(function () {
        var _a;
        orderAlternatives((_a = question === null || question === void 0 ? void 0 : question.perguntas[currentQuestionIndex]) === null || _a === void 0 ? void 0 : _a.alternativas);
        setCurrentQuestion(question === null || question === void 0 ? void 0 : question.perguntas[0]);
        setCurrentQuestionIndex(0);
    }, [question, id]);
    var hasNextQuestion = function () {
        return currentQuestionIndex < (question === null || question === void 0 ? void 0 : question.quantidadeTotalPerguntas);
    };
    var nextQuestion = function () {
        setReadyAnswer(false);
        setAnswered(false);
        setAnswerValue('');
        setBase64Image('');
        resetScroll();
        if (hasNextQuestion()) {
            setCurrentQuestion(question === null || question === void 0 ? void 0 : question.perguntas[currentQuestionIndex + 1]);
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        }
        else {
            setCurrentQuestionIndex(0);
        }
        if (currentQuestionIndex + 1 + (question === null || question === void 0 ? void 0 : question.quantidadePerguntasRespondidas) === (question === null || question === void 0 ? void 0 : question.quantidadeTotalPerguntas)) {
            setFinishedQuestions(true);
        }
    };
    useEffect(function () {
        if ((question === null || question === void 0 ? void 0 : question.quantidadePerguntasRespondidas) !== undefined) {
            if ((question === null || question === void 0 ? void 0 : question.quantidadePerguntasRespondidas) === (question === null || question === void 0 ? void 0 : question.quantidadeTotalPerguntas)) {
                setFinishedQuestions(true);
            }
        }
    }, [question]);
    var handleAnswer = function () {
        setLoading(true);
        var data = {
            questionarioId: question.id,
            perguntaId: currentQuestion.id,
            resposta: '',
            imagem: null,
            data: '',
        };
        if ((currentQuestion.tipoPergunta === ITipoPergunta.MultiplaEscolhaComOutros && answerValue) ||
            currentQuestion.tipoPergunta === ITipoPergunta.Texto ||
            currentQuestion.tipoPergunta === ITipoPergunta.Numero ||
            currentQuestion.tipoPergunta === ITipoPergunta.EscalaEmNumero) {
            data.resposta = answerValue;
            var isValidResponse = schema.isValidSync({
                resposta: data.resposta,
            });
            if (!isValidResponse) {
                setLoading(false);
                return setIsValid('red');
            }
        }
        else {
            data.resposta = currentAnswerId + '';
        }
        if (currentQuestion.tipoPergunta === ITipoPergunta.Imagem) {
            data.imagem = base64Image;
        }
        if (currentQuestion.tipoPergunta === ITipoPergunta.Data) {
            data.data = answerValue;
        }
        axiosClient.put(QUESTION_URL, data).then(function (response) {
            var _a;
            if (((_a = response.data) === null || _a === void 0 ? void 0 : _a.succeeded) == true) {
                handleRightAnswer();
            }
            else {
                handleWrongAnswer();
            }
        });
    };
    var uploadImage = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var file, base64;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    file = e.target.files[0];
                    return [4 /*yield*/, convertFileToBase64(file)];
                case 1:
                    base64 = _a.sent();
                    setBase64Image(base64);
                    return [2 /*return*/];
            }
        });
    }); };
    var hiddenFileInput = React.useRef(null);
    var handleClick = function () {
        hiddenFileInput.current.click();
    };
    var marksValuesArray = [];
    for (var index = 0; index <= 10; index++) {
        marksValuesArray.push({ value: index, label: index.toString() });
    }
    var handleOnChangeDate = function (event) {
        setReadyAnswer(true);
        if (moment(event.target.value).format().split('-')[0].replace(/^0+/, '').length != 4) {
            setReadyAnswer(false);
        }
        else {
            setAnswerValue(moment(event.target.value).format());
        }
    };
    var orderAlternatives = function (alternatives) {
        return alternatives === null || alternatives === void 0 ? void 0 : alternatives.sort(function (a, b) { return a.ordem - b.ordem; });
    };
    useEffect(function () {
        var progressPercentage = Math.round(((currentQuestionIndex + (question === null || question === void 0 ? void 0 : question.quantidadePerguntasRespondidas)) * 100) / (question === null || question === void 0 ? void 0 : question.quantidadeTotalPerguntas));
        setProgress(!Number.isNaN(progressPercentage) ? progressPercentage : 0);
    }, [currentQuestionIndex, question, id, currentQuestion]);
    var handleCurrentIndexToShowWithQuestion = function (title) {
        var stringF = "" + title;
        return stringF;
    };
    useEffect(function () {
        if ((currentQuestion === null || currentQuestion === void 0 ? void 0 : currentQuestion.tipoPergunta) === ITipoPergunta.EscalaEmNumero) {
            setAnswerValue(0);
        }
        else {
            setAnswerValue('');
        }
    }, [currentQuestion]);
    var divRef = React.useRef(null);
    var resetScroll = function () {
        if (divRef.current) {
            divRef.current.scrollTop = 0;
        }
    };
    var handleScrollDown = function () {
        var _a;
        (_a = bottomRef.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ behavior: 'smooth' });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(OuterDiv, null,
            React.createElement(StyledContainer, { primaryColor: true, className: 'use-inverse-colors' },
                React.createElement(HeaderV2, { companyLogoURL: company === null || company === void 0 ? void 0 : company.imgLogoEscuro, backButtonFn: function () { return history.push(AppPath.Research); } })),
            React.createElement(Typography, { sx: { fontFamily: FontFamily.Inter700, fontSize: '20px', color: '#49443C' }, variant: "h1", component: "div", textAlign: "center" },
                React.createElement(HTMLDisplayToQuestion, { htmlContent: currentQuestion === null || currentQuestion === void 0 ? void 0 : currentQuestion.titulo })),
            !isLoading ? (React.createElement("div", { style: { paddingInline: '10px', height: '100%' }, ref: divRef },
                finishedQuestions ? (React.createElement(React.Fragment, null,
                    React.createElement(FlexCol, { style: { alignItems: 'center', gap: '16px', marginTop: '24px' } },
                        React.createElement(Typography, { sx: {
                                fontFamily: FontFamily.Inter600,
                                fontSize: '14px',
                                color: '#000',
                                wordWrap: 'break-word',
                            }, component: "span", textAlign: "center", display: "block" }, "Pesquisa finalizada!"),
                        React.createElement("img", { src: newCheckIcon, alt: "page-complete-img", style: { width: '128px' } }),
                        React.createElement(Typography, { sx: {
                                fontFamily: FontFamily.Inter600,
                                fontSize: '14px',
                                color: '#000',
                                wordWrap: 'break-word',
                            }, component: "span", textAlign: "center", display: "block" }, "Agradecemos a sua dedica\u00E7\u00E3o")))) : (React.createElement(React.Fragment, null,
                    (currentQuestion === null || currentQuestion === void 0 ? void 0 : currentQuestion.video) && React.createElement(VideoPlayer, { videoUrl: (_a = currentQuestion === null || currentQuestion === void 0 ? void 0 : currentQuestion.video) === null || _a === void 0 ? void 0 : _a.url }),
                    React.createElement(Separator, null),
                    (currentQuestion === null || currentQuestion === void 0 ? void 0 : currentQuestion.tipoPergunta) === ITipoPergunta.Numero && (React.createElement("div", { style: { paddingLeft: '8px' } },
                        React.createElement(StyledSlider, { valueLabelDisplay: "auto", marks: marksValuesArray, min: 0, max: 10, onChange: function (e, value) {
                                setReadyAnswer(true);
                                setAnswerValue(value.toString());
                            }, value: answerValue ? parseInt(answerValue) : 5 }))),
                    (currentQuestion === null || currentQuestion === void 0 ? void 0 : currentQuestion.tipoPergunta) === ITipoPergunta.Texto && (React.createElement(Row, null,
                        React.createElement(Col, { xs: 12, md: 12 },
                            React.createElement(Textarea, { style: {
                                    width: '100%',
                                    minHeight: '100px',
                                    borderRadius: '10px',
                                    padding: '15px',
                                }, placeholder: "Digite sua resposta aqui. *Limite de 900 caracteres.", onChange: function (e) {
                                    setAnswerValue(e.target.value);
                                    setReadyAnswer(true);
                                }, value: answerValue, color: isValid }),
                            isValid == 'red' && (React.createElement(ErrorResponse, null, "Sua resposta deve ter no m\u00E1ximo 900 caracteres."))))),
                    (currentQuestion === null || currentQuestion === void 0 ? void 0 : currentQuestion.tipoPergunta) === ITipoPergunta.EscalaEmNumero && (React.createElement("div", { style: { display: 'flex', alignItems: 'center', justifyContent: 'center', paddingInline: '24px' } },
                        React.createElement(NumberInput, null,
                            React.createElement("input", { type: "number", placeholder: "Insira uma escala em n\u00FAmero", value: answerValue, required: true }),
                            React.createElement(FlexCol, { style: { gap: '6px' } },
                                React.createElement("button", { onClick: function () {
                                        //@ts-ignore
                                        setAnswerValue(function (prevValue) { return parseInt(prevValue) + 1; });
                                        setReadyAnswer(true);
                                    } }, "\u25B2"),
                                React.createElement("button", { onClick: function () {
                                        // @ts-ignore
                                        setAnswerValue(function (prevValue) { return parseInt(prevValue) - 1; });
                                        setReadyAnswer(true);
                                    } }, "\u25BC"))))),
                    (currentQuestion === null || currentQuestion === void 0 ? void 0 : currentQuestion.tipoPergunta) === ITipoPergunta.MultiplaEscolha && (React.createElement(NewQuestionBodyComponent, { alternatives: (_b = currentQuestion === null || currentQuestion === void 0 ? void 0 : currentQuestion.alternativas) === null || _b === void 0 ? void 0 : _b.map(function (item) { return ({
                            id: item.id,
                            title: item.descricao,
                            order: item.ordem,
                        }); }), onSelectAlternative: function (questionId) {
                            setCurrentAnswerId(questionId);
                            setReadyAnswer(true);
                            handleScrollDown();
                        } })),
                    (currentQuestion === null || currentQuestion === void 0 ? void 0 : currentQuestion.tipoPergunta) === ITipoPergunta.MultiplaEscolhaComOutros && (React.createElement(NewQuestionBodyComponent, { alternatives: (_c = currentQuestion === null || currentQuestion === void 0 ? void 0 : currentQuestion.alternativas) === null || _c === void 0 ? void 0 : _c.map(function (item) { return ({
                            id: item.id,
                            title: item.descricao,
                            order: item.ordem,
                        }); }), incluirOpcaoOutros: true, onOpcaoOutrosTextValueChange: function (textValue) {
                            setAnswerValue(textValue);
                            setReadyAnswer(true);
                        }, onSelectAlternative: function (questionId) {
                            setCurrentAnswerId(questionId);
                            setReadyAnswer(true);
                        } })),
                    (currentQuestion === null || currentQuestion === void 0 ? void 0 : currentQuestion.tipoPergunta) === ITipoPergunta.Imagem && (React.createElement(Row, null,
                        React.createElement(Col, { xs: 12 },
                            React.createElement(Button, { variant: "link", outlined: true, expanded: true, onClick: handleClick, style: { height: base64Image != '' ? 'inherit' : '6rem' } }, base64Image != ''
                                ? 'Clique aqui para trocar a imagem escolhida'
                                : 'Clique aqui e envie sua imagem-resposta'),
                            React.createElement("input", { type: "file", ref: hiddenFileInput, onChange: function (e) {
                                    uploadImage(e);
                                    setReadyAnswer(true);
                                }, style: { display: 'none' } })),
                        React.createElement(Col, { xs: 3 }, base64Image && (React.createElement(StyledContainer, { justifyCenter: true, alignCenter: true, style: { height: '100%' } },
                            React.createElement(PreviewLabel, null, "Imagem escolhida:")))),
                        React.createElement(PreviewImage, { xs: 9 },
                            React.createElement(StyledContainer, { justifyCenter: true },
                                React.createElement("img", { src: base64Image, alt: "", height: "100%" }))))),
                    (currentQuestion === null || currentQuestion === void 0 ? void 0 : currentQuestion.tipoPergunta) === ITipoPergunta.Data && (React.createElement(Row, null,
                        React.createElement(Col, { xs: 12, md: 12, style: { display: 'flex', alignItems: 'center', justifyContent: 'center' } },
                            React.createElement(InputDate, { type: "date", onChange: handleOnChangeDate, required: true, autoFocus: true })))),
                    (currentQuestion === null || currentQuestion === void 0 ? void 0 : currentQuestion.tipoPergunta) === ITipoPergunta.AssociarUsuario && (React.createElement(FlexCol, null,
                        React.createElement(FlexRowBot, { style: { width: '100%' } },
                            React.createElement(InputSearch, { type: "text", value: userSearch, placeholder: "Pesquisar usu\u00E1rio..", onChange: function (e) { return setUserSearch(e.target.value.toString()); }, id: "inputSearch" }),
                            React.createElement(SearchIcon, { sx: { fontSize: 40 } })),
                        usersData.map(function (item, index) {
                            return (React.createElement(QuestionCard, { active: question.id === currentAnswerId, key: index, onClick: function () {
                                    console.log(item.id, item);
                                    setCurrentAnswerId(item.id);
                                    setReadyAnswer(true);
                                    handleScrollDown();
                                } },
                                React.createElement("input", { type: "radio", value: item.id, checked: item.id === currentAnswerId }),
                                React.createElement(Typography, { sx: { fontFamily: FontFamily.Inter400, fontSize: '14px' }, variant: "h5", component: "span" }, item.nome)));
                        }),
                        !isFetching && !usersData.length && (React.createElement(SubmenuTitle, { style: { fontSize: '1rem', textAlign: 'center' } }, "Nenhum usuario encontrado..")),
                        isFetching && (React.createElement(FlexColumn, { style: { paddingBlock: '2rem' } },
                            React.createElement(ActivityIndicator, { type: "spinner", size: "2x" }))),
                        hasNextPage && !isFetching && (React.createElement(RoundedButton, { onClick: fetchNextPage },
                            React.createElement(SubmenuTitle, { style: { fontSize: '1rem' } }, "Ver mais.."))))))),
                React.createElement(Separator, null),
                React.createElement(Footer, null,
                    !finishedQuestions ? (React.createElement(Button, { ref: bottomRef, expanded: true, onClick: function () {
                            if (answered) {
                                nextQuestion();
                            }
                            else {
                                handleAnswer();
                            }
                        }, loading: loading, style: { backgroundColor: '#008425', borderColor: '#008425', borderRadius: '16px' }, disabled: !readyAnswer ||
                            ((currentQuestion === null || currentQuestion === void 0 ? void 0 : currentQuestion.tipoPergunta) === ITipoPergunta.EscalaEmNumero && answerValue === '') ||
                            ((currentQuestion === null || currentQuestion === void 0 ? void 0 : currentQuestion.tipoPergunta) === ITipoPergunta.Texto &&
                                removeSpecialChars(answerValue).length < 1) ||
                            ((currentQuestion === null || currentQuestion === void 0 ? void 0 : currentQuestion.tipoPergunta) === ITipoPergunta.Imagem && base64Image === '') ||
                            ((currentQuestion === null || currentQuestion === void 0 ? void 0 : currentQuestion.tipoPergunta) === ITipoPergunta.MultiplaEscolhaComOutros &&
                                currentAnswerId === 0 &&
                                (answerValue === null || answerValue === void 0 ? void 0 : answerValue.trim()) === '') }, answered ? (React.createElement(React.Fragment, null,
                        React.createElement("span", null, hasNextQuestion() ? 'PRÓXIMA' : 'CONCLUIR'),
                        React.createElement(Countdown, { date: Date.now() + 1000, renderer: function (_a) {
                                var seconds = _a.seconds;
                                return React.createElement("span", null,
                                    "(",
                                    zeroPad(seconds),
                                    ")");
                            }, onComplete: function () { return nextQuestion(); } }))) : (React.createElement(React.Fragment, null, !(question === null || question === void 0 ? void 0 : question.valePontos) ? (React.createElement(Typography, { sx: { fontFamily: FontFamily.Inter600, fontSize: '16px' }, variant: "h5", component: "span" }, "CONTINUAR")) : (React.createElement(FlexRow, { style: { alignItems: 'center', justifyContent: 'center' } },
                        React.createElement(Typography, { sx: { fontFamily: FontFamily.Inter600, fontSize: '16px' }, variant: "h5", component: "span" }, "Ganhe + 30"),
                        React.createElement("img", { src: entcoinsIconSrc, alt: "moeda", style: { height: '24px', width: '24px', marginLeft: '12px' } }))))))) : (React.createElement("div", { style: { width: '100%', alignItems: 'center' } },
                        React.createElement(ButtonAndamento, { Background: true, onClick: function () { return history.push({ pathname: AppPath.Map }); } },
                            "Voltar para o Menu",
                            React.createElement(Countdown, { date: Date.now() + 10000, renderer: function (_a) {
                                    var seconds = _a.seconds;
                                    return React.createElement("span", null,
                                        " (",
                                        seconds,
                                        "s)");
                                }, onComplete: function () { return history.push({ pathname: AppPath.Map }); } })))),
                    React.createElement(FlexRow, { style: { justifyContent: 'space-between', width: '100%', marginTop: '32px', alignItems: 'center' } },
                        React.createElement("div", { style: { width: '90%' } },
                            React.createElement(ProgressBar, { baseBgColor: "#F0F0F0", bgColor: !finishedQuestions ? '#1677FF' : '#52C41A', completed: !finishedQuestions ? progress : 100, width: "100%", height: '10px', isLabelVisible: false, maxCompleted: 100 })),
                        React.createElement(React.Fragment, null, !finishedQuestions ? (React.createElement(Typography, { sx: { fontFamily: FontFamily.Inter400, fontSize: '14px' }, variant: "h5", component: "span" },
                            progress,
                            "%")) : (React.createElement("img", { src: newCheckCircle, alt: "page-complete-icon" }))))))) : (React.createElement(FlexRow, { style: { marginLeft: '48%' } },
                React.createElement(ActivityIndicator, { type: "spinner" })))),
        React.createElement(BottomNav, null)));
};
