var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import { Typography } from '@mui/material';
import ProgressBar from '@ramonak/react-progress-bar';
import { useStore } from 'react-context-hook';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { HeaderV2 } from '@app/components/mol.header/header.component-v2';
import { TrackHeader } from '@app/components/mol.track-header';
import { NotificationDrawer } from '@app/components/notification-drawer';
import { useFlashMessage } from '@app/components/obj.flash-message';
import { axiosClient } from '@app/configure-app';
import { FlexRow } from '@app/modules/routine-checklist/quick-checklist/styles';
import { QuizContext } from '@app/providers/quiz.store';
import newCheckCircle from '@assets/icons/new-check-circle.svg';
import { FlashMessageEnum, FontFamily, StyledContainer } from '@atomic';
import { Footer } from '../components/footer/footer.component';
import { Quiz } from '../components/quiz/quiz.component';
var answerQuestionUrl = function (_a) {
    var faseId = _a.faseId, quizId = _a.quizId, tarefaId = _a.tarefaId;
    return "/" + faseId.toString() + "/tarefa/" + tarefaId.toString() + "/quiz/" + quizId.toString();
};
export var QuizPage = function (props) {
    var _a, _b, _c, _d, _e;
    var history = useHistory();
    var _f = React.useState(), progress = _f[0], setProgress = _f[1];
    var company = useStore('company')[0];
    var _g = React.useContext(QuizContext), quizzes = _g[0], currentQuizIndex = _g[1];
    var loadedCourse = useStore('currentCourse')[0];
    var showFlashMessage = useFlashMessage()[0];
    var domain = useStore('domain')[0];
    var tarefa = props.location.state.tarefa;
    var faseId = (tarefa || {}).faseId;
    var quizId = (_a = quizzes === null || quizzes === void 0 ? void 0 : quizzes[currentQuizIndex]) === null || _a === void 0 ? void 0 : _a.id;
    var tarefaId = (_b = quizzes === null || quizzes === void 0 ? void 0 : quizzes[currentQuizIndex]) === null || _b === void 0 ? void 0 : _b.tarefaId;
    var _h = React.useState(false), showNotification = _h[0], setShowNotification = _h[1];
    var scrollBottom = function () {
        setTimeout(function () {
            window.scrollTo(0, document.body.scrollHeight);
        }, 200);
    };
    var answerQuestionMutation = function (params) { return __awaiter(void 0, void 0, void 0, function () {
        var response, pontuacaoNoQuizAtual, somaDoMontanteComPontuacaoAtual, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axiosClient.post(answerQuestionUrl({ faseId: faseId, quizId: quizId, tarefaId: tarefaId }), {
                            quizId: quizId,
                            quizAlternativaId: params.quizAlternativaId,
                        })];
                case 1:
                    response = _a.sent();
                    scrollBottom();
                    pontuacaoNoQuizAtual = parseInt(localStorage.getItem('pontosDoUsuarioNaRodada'));
                    somaDoMontanteComPontuacaoAtual = response.data.data.pontos + pontuacaoNoQuizAtual;
                    localStorage.setItem('pontosDoUsuarioNaRodada', somaDoMontanteComPontuacaoAtual.toString());
                    return [2 /*return*/, response.data];
                case 2:
                    error_1 = _a.sent();
                    showFlashMessage(FlashMessageEnum.alert, 'Erro ao tentar responder a pergunta.');
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    React.useEffect(function () {
        if (localStorage.getItem('pontosDoUsuarioNaRodada') == null) {
            localStorage.setItem('pontosDoUsuarioNaRodada', '0');
        }
    }, []);
    var _j = useMutation(answerQuestionMutation), mutateAnswerQuestion = _j.mutate, answerResponse = _j.data, resetAnswerQuestion = _j.reset, isLoading = _j.isLoading;
    var clearNotification = function (timer) {
        setShowNotification(false);
        clearTimeout(timer);
    };
    React.useEffect(function () {
        if (answerResponse) {
            setShowNotification(true);
            var timer_1 = setTimeout(function () {
                setShowNotification(false);
            }, 3000);
            return function () { return clearNotification(timer_1); };
        }
    }, [answerResponse]);
    var recoverFromDisaster = function () {
        window.location.href = "/" + domain + "/";
    };
    var getTitleHeader = function () {
        var _a, _b, _c;
        if (!loadedCourse || !loadedCourse.fases) {
            recoverFromDisaster();
        }
        var courseTitle = loadedCourse === null || loadedCourse === void 0 ? void 0 : loadedCourse.nome;
        var quiz = quizzes === null || quizzes === void 0 ? void 0 : quizzes[currentQuizIndex];
        var task = (_a = loadedCourse === null || loadedCourse === void 0 ? void 0 : loadedCourse.fases) === null || _a === void 0 ? void 0 : _a.flatMap(function (fase) { return fase.tarefas; }).find(function (taref) { return taref.id === quiz.tarefaId; });
        var phaseTitle = (_c = (_b = loadedCourse === null || loadedCourse === void 0 ? void 0 : loadedCourse.fases) === null || _b === void 0 ? void 0 : _b.find(function (fase) { return fase.id === (task === null || task === void 0 ? void 0 : task.faseId); })) === null || _c === void 0 ? void 0 : _c.titulo;
        var taskTitle = task === null || task === void 0 ? void 0 : task.nome;
        return courseTitle + " \u2192 " + phaseTitle + " \u2192 " + taskTitle;
    };
    React.useEffect(function () {
        var progressValue = (currentQuizIndex * 100) / quizzes.length;
        setProgress(Number(progressValue.toFixed(2)));
    }, [quizzes, currentQuizIndex]);
    return (React.createElement(React.Fragment, null,
        React.createElement(NotificationDrawer, { show: showNotification, isSuccess: (_c = answerResponse === null || answerResponse === void 0 ? void 0 : answerResponse.data) === null || _c === void 0 ? void 0 : _c.acertou, successTitle: "Parab\u00E9ns!", successMessage: "Voc\u00EA ganhou " + ((_d = answerResponse === null || answerResponse === void 0 ? void 0 : answerResponse.data) === null || _d === void 0 ? void 0 : _d.pontos) + " pontos!", failTitle: "Resposta errada!", failMessage: ((_e = answerResponse === null || answerResponse === void 0 ? void 0 : answerResponse.data) === null || _e === void 0 ? void 0 : _e.numeroTentativa) > 1 ? '' : 'Ganhe pontos na próxima!' }),
        React.createElement(StyledContainer, { primaryColor: true, className: 'use-inverse-colors' },
            React.createElement(HeaderV2, { withBackButton: true, companyLogoURL: company === null || company === void 0 ? void 0 : company.imgLogoEscuro })),
        React.createElement(TrackHeader, { noTitle: true }),
        React.createElement(Typography, { sx: { fontFamily: FontFamily.Inter700, fontSize: '20px', color: '#49443C' }, variant: "h1", component: "div", textAlign: "center" }, getTitleHeader()),
        React.createElement(Quiz, { tarefa: tarefa, answerResponse: answerResponse }),
        React.createElement(Footer, { mutateAnswerQuestion: mutateAnswerQuestion, isMutateAnswerLoading: isLoading, resetAnswerQuestion: resetAnswerQuestion, answerResponse: answerResponse, tarefa: tarefa }),
        React.createElement(FlexRow, { style: {
                justifyContent: 'space-between',
                width: '100%',
                marginTop: '32px',
                alignItems: 'center',
                paddingInline: '16px',
            } },
            React.createElement("div", { style: { width: '90%' } },
                React.createElement(ProgressBar, { baseBgColor: "#F0F0F0", bgColor: progress !== 100 ? '#1677FF' : '#52C41A', completed: progress, width: "100%", height: '10px', isLabelVisible: false, maxCompleted: 100 })),
            React.createElement(React.Fragment, null, progress !== 100 ? (React.createElement(Typography, { sx: { fontFamily: FontFamily.Inter400, fontSize: '14px' }, variant: "h5", component: "span" },
                progress,
                "%")) : (React.createElement("img", { src: newCheckCircle, alt: "page-complete-icon" }))))));
};
