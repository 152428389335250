import { format, parse } from 'date-fns';
import { axiosClient } from '@app/configure-app';
var options = {
    enableHighAccuracy: true,
    timeout: 2000,
    maximumAge: 0,
};
var LOCATION_LS_TOKEN_KEY = 'ent-location-last-register';
export var getUserLocation = function () {
    return new Promise(function (resolve, reject) {
        navigator.geolocation.getCurrentPosition(function (position) {
            resolve({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
            });
        }, function (error) {
            reject(error);
        }, options);
    });
};
export var postLocation = function (location) {
    var POST_LOCATION_URL = 'localizacao';
    axiosClient.post(POST_LOCATION_URL, location);
};
var saveLSToken = function (usuarioId) {
    var currentDate = format(new Date(), 'uuuu-M-dd');
    localStorage.setItem(LOCATION_LS_TOKEN_KEY, usuarioId + "#" + currentDate);
};
export var registerLastLocation = function (usuarioId) {
    getUserLocation()
        .then(function (location) {
        postLocation(location);
        saveLSToken(usuarioId);
    })
        .catch(function (error) {
        console.error(error);
    });
};
export var monitorLocation = function (usuarioId) {
    if (!hasRegisteredToday()) {
        registerLastLocation(usuarioId);
    }
};
var hasRegisteredToday = function () {
    var lsToken = localStorage.getItem(LOCATION_LS_TOKEN_KEY);
    if (!lsToken) {
        return false;
    }
    var strDate = lsToken.split('#')[1];
    var lastDate = parse(strDate, 'uuuu-M-dd', new Date());
    var today = new Date();
    return (lastDate.getDate() === today.getDate() &&
        lastDate.getMonth() === today.getMonth() &&
        lastDate.getFullYear() === today.getFullYear());
};
export var DataAtualFormatada = function () {
    var current = new Date();
    var cDate = current.getFullYear() + '-' + (current.getMonth() + 1) + '-' + current.getDate();
    return cDate;
};
